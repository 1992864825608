import './vendor/bootstrap/css/bootstrap.min.css';
import './vendor/fontawesome-free/css/all.min.css';
import './vendor/animate/animate.compat.css';

import './vendor/simple-line-icons/css/simple-line-icons.min.css';
import './vendor/owl.carousel/assets/owl.carousel.min.css';
import './vendor/owl.carousel/assets/owl.theme.default.min.css';


import './vendor/magnific-popup/magnific-popup.min.css';
import './vendor/magnific-popup/jquery.magnific-popup.min.js';

import './css/theme.css';
import './css/theme-elements.css';
import './css/theme-blog.css';
import './css/theme-shop.css';
import './css/custom.css';

import './css/demos/demo-resume.css';
import './css/skins/skin-resume-orange.css';

import './vendor/jquery/jquery.min.js';
import './vendor/jquery.easing/jquery.easing.min.js';

import './vendor/modernizr/modernizr.min.js';

import './vendor/plugins/js/plugins.min.js';

import './js/theme.js';
import './js/views/view.contact.js';
import './js/demos/demo-resume.js';
import './js/custom.js';

import './js/theme.init.js';
